import React from "react"
import CoursePage from "../../../components/Course/CoursePage"
import { Box, Text } from "theme-ui"

const OnlineCourse = (): JSX.Element => {
  return (
    <CoursePage
      heading="Online Course"
      courses={[
        {
          name: "Complete Course (GS Pre Cum Mains + CSAT, Optional Subject)",
          price: "Rs.1,98,500/-",
          duration: "11 Months",
        },
        {
          name: "Pre Cum Mains Course (GS Pre Cum Mains + CSAT)",
          price: "Rs.1,75,000/-",
          duration: "10 Months",
        },
        {
          name: "Foundation Course",
          price: "Rs.2,35,000/-",
          duration: "36 Months",
        },
        {
          name: "Optional Subject Course",
          price: "Rs.48,000/-",
          duration: "4 & Half Months",
        },
        {
          name: "Weekend Course",
          price: "Rs. 1,25,000/-",
          duration: "15 Months",
        },
      ]}
      description={
        <Box>
          <Text sx={{ fontSize: 3 }}>
            In the 21th century virtual education is lead to more success in the
            part of education.
          </Text>
          <Text sx={{ fontSize: 3 }} mt={4}>
            Sometimes, The most difficult part of the preparation is finding the
            right institute. That should be close to your place. But It’s not
            feasible for everyone to relocate to receive the best UPSC Coaching
            experience.
          </Text>
          <Text sx={{ fontSize: 3 }} mt={4}>
            In the COVID19 Pandemic time, it has become even more difficult to
            continue with UPSC Preparation. To solve this issue, SARKAR IAS
            ACADEMY has launched the Online Classes for UPSC IAS preparation in
            perspective view.
          </Text>
          <Text sx={{ fontSize: 3 }} mt={4}>
            Now Don’t relocate in order to receive the best UPSC Coaching
            experience. SARKAR organize a wonderful live experience equal to
            class room feeling through online class and success for your trusted
            dream.
          </Text>
          <Text sx={{ fontSize: 3 }} mt={4}>
            SARKAR comes inside of your studying hall in the most your home and
            nearly your hands and eyes with your permission.
          </Text>
          <Text sx={{ fontSize: 3 }} mt={4}>
            Now, there is no travelling and no worries about distance. Only, you
            can focus and concentrate your best study effectively with our
            guideline of more efficient.
          </Text>
          <Text sx={{ fontSize: 3 }} mt={4}>
            Can Learn from India's Top mentors anywhere, anytime under IAS
            Online Coaching program
          </Text>
          <Text sx={{ fontSize: 3 }} mt={4}>
            Our experts provide UPSC Online Coaching of excellent, time-tested
            standard form.
          </Text>
          <Text sx={{ fontSize: 3 }} mt={4}>
            Every Aspirant can easily study from anyplace and get naturally
            access IAS Online Classes.
          </Text>
          <Text sx={{ fontSize: 3 }} mt={4}>
            UPSC is a one of the most Powerful and Recognized posting of
            Government in our nation. Your dream is enter that powerful
            organization to get power and to serve the people.
          </Text>
          <Text sx={{ fontSize: 3 }} mt={4}>
            Every big dream is start from a small incident. Now you have a big
            dream. That dream is Living in your heart as UPSC which is IAS, IPS,
            IFS etc.,
          </Text>
          <Text sx={{ fontSize: 3 }} mt={4}>
            Join Online IAS Coaching Classes Today with your treasure of a big
            dream.
          </Text>
        </Box>
      }
    />
  )
}

export default OnlineCourse
